<template>
  <nav class="navbar" v-if="user">
    <ul class="navbar__menu">
      <li class="navbar__item">
        <router-link :to="'/admin/profile/' + user.id" class="navbar__link">
          <div class="photo">
            <img :src="user.avatarUrl" alt="Avatar" class="avatar" />
          </div>
          <span>{{ user.username }}</span></router-link
        >
      </li>
      <li class="navbar__item">
        <a href="/" class="navbar__link">
          <Icon
            icon="ic:baseline-home"
            width="2em"
            height="2em"
            style="color: white"
          />
          <span>Home</span></a
        >
      </li>
      <li class="navbar__item">
        <a href="/admin/manga-management" class="navbar__link">
          <Icon
            icon="solar:book-bold"
            width="2em"
            height="2em"
            style="color: white"
          /><span>Seriler</span></a
        >
      </li>
      <li class="navbar__item">
        <a href="/admin/slider-setup" class="navbar__link"
          ><Icon
          icon="solar:slider-vertical-bold"
          width="2em"
          height="2em"
          style="color: white"
        /><span>Slider Setup</span></a
        >
      </li>
      <li class="navbar__item">
        <a href="/admin/user-list" class="navbar__link"
          ><Icon
            icon="clarity:users-solid"
            width="2em"
            height="2em"
            style="color: white"
          /><span>Customers</span></a
        >
      </li>
      <li class="navbar__item">
        <LogoutButton class="navbar__link"
          ><span>Logout</span></LogoutButton
        >
      </li>
      
      <li class="navbar__item">
        <a href="#" class="navbar__link"
          ><i data-feather="help-circle"></i><span>Help</span></a
        >
      </li>
      <li class="navbar__item">
        <a href="#" class="navbar__link"
          ><i data-feather="settings"></i><span>Settings</span></a
        >
      </li>
    </ul>
  </nav>
</template>
<script setup></script>
<script>
import { Icon } from "@iconify/vue";
import LogoutButton from "@/components/Admin/LogoutButton.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AdminView",
  components: {
    Icon,
    LogoutButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.fetchUser();
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
};
</script>
<style scoped>
.photo {
  width: 3rem;
  height: 3rem;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.avatar {
  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;
}
li {
  list-style-type: none;
}
</style>

<style lang="scss" scoped>
$borderRadius: 10px;
$spacer: 1rem;
$primary: #406ff3;
$text: #6a778e;
$linkHeight: $spacer * 3.5;
$timing: 250ms;
$transition: $timing ease all;

@mixin gooeyEffect($i) {
  @keyframes gooeyEffect-#{$i} {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
body {
  background: #1c1c1c;
  font-family: "Open Sans", sans-serif;
}
.navbar {
  $ref: &;
  position: fixed;
  top: $spacer;
  left: $spacer;
  background: #1c1c1c;
  border-radius: $borderRadius;
  padding: $spacer 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.03);
  height: calc(100vh - #{$spacer * 4});
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $linkHeight;
    width: $spacer * 5.5;
    color: $text;
    transition: $transition;
    span {
      position: absolute;
      left: 100%;
      transform: translate(-($spacer * 3));
      margin-left: 1rem;
      opacity: 0;
      pointer-events: none;
      color: $primary;
      background: #fff;
      padding: $spacer * 0.75;
      transition: $transition;
      border-radius: $borderRadius * 1.75;
    }
    &:hover {
      color: #fff;
    }
    .navbar:not(:hover) &:focus,
    &:hover {
      span {
        opacity: 1;
        transform: translate(0);
      }
    }
  }
  &__menu {
    position: relative;
  }
  &__item {
    &:last-child {
      &:before {
        content: "";
        position: absolute;
        opacity: 0;
        z-index: -1;
        top: 0;
        left: $spacer * 1.5;
        width: $linkHeight;
        height: $linkHeight;
        background: $primary;
        border-radius: $borderRadius * 1.75;
        transition: $timing cubic-bezier(1, 0.2, 0.1, 1.2) all;
      }
    }

    @for $i from 1 to 12 {
      &:first-child:nth-last-child(#{$i}),
      &:first-child:nth-last-child(#{$i}) ~ li {
        &:hover {
          ~ li:last-child:before {
            opacity: 1;
          }
        }
        &:last-child:hover:before {
          opacity: 1;
        }
        @for $j from 1 to $i {
          &:nth-child(#{$j}):hover {
            ~ li:last-child:before {
              @include gooeyEffect($j);
              top: (100% / $i) * ($j - 1);
              animation: gooeyEffect-#{$j} $timing 1;
            }
          }
        }
        &:last-child:hover:before {
          @include gooeyEffect($i);
          top: (100% / $i) * ($i - 1);
          animation: gooeyEffect-#{$i} $timing 1;
        }
      }
    }
  }
}
</style>
