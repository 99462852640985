<template>
    <div>
      <h2>Manga Reader</h2>
      <!-- Manga okuma içeriği buraya gelecek -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'MangaReader',
    props: {
      id: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Stil eklemek isterseniz buraya yazabilirsiniz */
  </style>
  