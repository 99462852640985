<template>
    <div>
      <h1>Verify Email</h1>
      <form @submit.prevent="verifyEmail">
        <input v-model="email" placeholder="Email" />
        <input v-model="code" placeholder="Verification Code" />
        <button type="submit">Verify</button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        code: '',
        message: ''
      };
    },
    methods: {
      verifyEmail() {
        axios.post(process.env.VUE_APP_AXIOS+'/auth/verify-email', {
          email: this.email,
          code: this.code
        })
        .then(response => {
          localStorage.setItem('token', response.data.token);
          this.$router.push('/login');
        })
        .catch(error => {
          console.error(error);
          this.message = 'Invalid verification code. Please try again.';
        });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Stil eklemeleri */
  </style>
  