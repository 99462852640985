<template>
    <div class="new-realese-container">
      <div class="new-realese">
        <div class="header2">
          <div class="title">New Release</div>
          <div class="nav-btn-container">
            <!-- Önceki slayta git -->
            <button @click="prevSlide" class="nav-button prev-button">◀</button>
            <!-- Sonraki slayta git -->
            <button @click="nextSlide" class="nav-button next-button">▶</button>
          </div>
        </div>
      </div>
  
      <div class="realease-wrapper flex-col">
        <div class="realese-list" :style="{ transform: `translateX(-${currentIndex * (100/updateSlidesPerPage)}%)` }">
          <!-- Manga kartlarını döngü ile oluştur -->
          <div class="realese" v-for="(manga, index) in visibleMangas" :key="index">
            <div class="realese-photo">
              <img :src="manga.coverImageUrl" :alt="manga.title" class="realese-image" />
            </div>
            <div class="realese-title">
              <p class="re-title">{{ manga.title }}</p>
            </div>
          </div>
        </div>
  
        <!-- Dot navigasyonu -->
        <div class="progress-dots">
          <span
            v-for="(dot, index) in totalSlides"
            :key="index"
            :class="{ active: currentIndex === index }"
            @click="goToSlide(index)"
          ></span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "NewReleaseCarousel",
    data() {
      return {
        currentIndex: 0, // Şu anki slayt indeksi
        slidesPerPage: 7, // Varsayılan olarak 7 öğe gösteriyoruz
      };
    },
    computed: {
      ...mapGetters(["mangas"]),
      // Sorted mangalar
      sortedMangas() {
        return this.mangas.slice().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      },
      // Toplam slayt sayısı
      totalSlides() {
        return Math.ceil(this.mangas.length / this.slidesPerPage);
      },
      // Ekran genişliğine göre gösterilecek manga sayısı
      visibleMangas() {
        return this.sortedMangas.slice(
          this.currentIndex * this.slidesPerPage,
          (this.currentIndex + 1) * this.slidesPerPage
        );
      }
    },
    methods: {
      ...mapActions(["fetchMangas"]),
      // Sonraki slayta git
      nextSlide() {
        if (this.currentIndex < this.totalSlides - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      },
      // Önceki slayta git
      prevSlide() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        } else {
          this.currentIndex = this.totalSlides - 1;
        }
      },
      // Belirli bir slayta git (dot'a tıklandığında)
      goToSlide(index) {
        this.currentIndex = index;
      },
      // Ekran yeniden boyutlandığında kart sayısını güncelle
      updateSlidesPerPage() {
       if (window.innerWidth <= 900) {
          this.slidesPerPage = 4;
        } 
        
        else {
          this.slidesPerPage = 7;
        }
      }
    },
    mounted() {
      // Bileşen yüklendiğinde ekran boyutunu kontrol et
      this.updateSlidesPerPage();
      window.addEventListener("resize", this.updateSlidesPerPage); // Ekran boyutunu izliyoruz
    },
    beforeUnmount() {
      // Bileşen kaldırıldığında etkinlik dinleyicisini kaldır
      window.removeEventListener("resize", this.updateSlidesPerPage);
    }
  };
  </script>
  
  <style scoped>
  .new-realese-container {
    display: flex;
    flex-direction: column;
    height: 30rem;
  }
  
  .new-realese {
    background-color: transparent;
    color: #1E2C43;
    transition: transform 1s ease-in-out;
  }
  
   .realese:hover{
    background-color: #283A58;
    scale: 1.05;
  }

  
  .header2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 1.8rem;
    font-family: Poppins, sans-serif;
    color: #cfd1d4;
  }
  
  .nav-button {
    background-color: #8f96a0;
    width: 20px;
    height: 20px;
    border: none;
    color: black;
    border-radius: 2px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
  
  .nav-button:hover {
    background-color: white;
  }
  
  .nav-btn-container {
    display: flex;
    gap: 5px;
  }
  
  .realease-wrapper {
    width: 100%;
    height: 20rem;
    display: flex;
    overflow: hidden;
    transition: transform 1s ease-in-out;
  }
  
  .realese-list {
    display: flex;
    gap: 10px;
    transition: transform 1s ease-in-out;
  }
  
  .realese {
    flex: 0 0 calc(100% / 7 - 10px);
    background: #1E2C43;
    border-radius: 15px;
    transition: transform 1s ease-in-out;
  }
  
  .realese-photo {
    height: 80%;
    background-color: black;
  }
  
  .realese-image {
    width: 100%;
    height: 100%;
  }
  
  .realese-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
  }
  
  .re-title {
    position: relative;
    font-weight: bold;
    text-align: center;
    
  }
  
  .progress-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .progress-dots span {
    width: 40px;
    height: 4px;
    margin: 0 5px;
    background-color: #777;
    border-radius: 15%;
    display: inline-block;
    cursor: pointer;
  }
  
  .progress-dots .active {
    background-color: white;
  }


  /* Orta boy ekranlar için (900px ve altı) */
  @media (max-width: 900px) {
    .realese {
      flex: 0 0 calc(100% / 4 - 10px); /* Orta boy ekranlarda 4 kart */
    }
  
    .new-realese-container {
      height: 25rem; /* Orta boy ekranlarda biraz daha yüksek */
    }
    .realese-title{
        overflow: hidden;
        height: 20%;
    }
  }
  
  /* Daha büyük ekranlar için */
  @media (min-width: 1200px) {
    .realese {
      flex: 0 0 calc(100% / 7 - 10px); /* Büyük ekranlarda 7 kart */
    }
  
    .new-realese-container {
      height: 30rem; /* Büyük ekranlar için kart yüksekliği */
    }
  }
  </style>
  