<template>
    <div >
      <h2 style="cursor:pointer;" @click="isShow=!isShow">Add New Manga +</h2>
      <div class="flex flex-row w-full gap-[20px]" v-show="isShow" >
        <div class="left-section flex flex-col w-[80%] h-[100%] gap-[20px]">
          <div class="form-group w-[100%]">
            
            <input
              v-model="newManga.title"
              id="title"
              placeholder="Title"
              class="text-white"
              required
            />
          </div>
          <div>
            <router-link :to="'/manga/'+newManga.id"
              >Kalıcı Bağlantı: http://localhost:8080/manga/{{
                newManga.id
              }}</router-link
            >
          </div>
          <div class="form-group">
            <textarea
              v-model="newManga.description"
              id="description"
              placeholder="Description"
              class="h-[200px] text-white"
              required
            ></textarea>
          </div>
          <div class="extra-detil box-content flex flex-row">
            <div class="extra-detil-left w-[20%]">
              <button
                class="pagination-btn"
                :class="{ active: activeTab == 0 }"
                @click="activeTab = 0"
              >
                Extra Detail
              </button>
              <button
                class="pagination-btn"
                :class="{ active: activeTab == 1 }"
                @click="activeTab = 1"
              >
                Chapter List
              </button>
              <button
                class="pagination-btn"
                :class="{ active: activeTab == 2 }"
                @click="activeTab = 2"
              >
                Upload Chapter
              </button>
              <button
                class="pagination-btn"
                :class="{ active: activeTab == 3 }"
                @click="activeTab = 3"
              >
                Upload Chapters
              </button>
            </div>
            <div class="extra-detil-right w-[80%]">
              <div v-show="activeTab == 0">
                <div class="flex gap-5 flex-row w-full justify-center form-group">
                <input class="w-[80%]" v-model="bakaUrl" />
                <button @click="otoDoldur">Oto Doldur</button>
              </div>
                <div class="form-group">
                  <label for="alternative">Alternative Name</label>
                  <input
                    v-model="newManga.alternative"
                    id="alternative"
                    placeholder="Alternative Name"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="status">Status</label>
                  <select class="text-black" v-model="newManga.status" id="status">
                    <option value="OnGoing">OnGoing</option>
                    <option value="Completed">Completed</option>
                    <option value="Hiatus">Hiatus</option>
                  </select>
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <input
                    v-model="newManga.type"
                    id="type"
                    placeholder="Type"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="rating">Rating</label>
                  <input
                    v-model="newManga.rating"
                    id="rating"
                    placeholder="Rating"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="reviews">Reviews</label>
                  <input
                    v-model="newManga.reviews"
                    id="reviews"
                    placeholder="Reviews"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="magazines">Magazines</label>
                  <input
                    v-model="newManga.magazines"
                    id="magazines"
                    placeholder="Magazines"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="author">Author</label>
                  <input
                    v-model="newManga.author"
                    id="author"
                    placeholder="Author"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="genres">Genres</label>
                  <input
                    v-model="newManga.genres"
                    id="genres"
                    placeholder="Genres"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="published">Published</label>
                  <input
                    v-model="newManga.published"
                    id="published"
                    placeholder="Published"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="calendarType">Calendar Type</label>
                  <input
                    v-model="newManga.calendarType"
                    id="calendarType"
                    placeholder="Calendar Type"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="calendarDay">Calendar Day</label>
                  <input
                    v-model="newManga.calendarDay"
                    id="calendarDay"
                    placeholder="Calendar Day"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="calendarWeek">Calendar Week</label>
                  <input
                    v-model="newManga.calendarWeek"
                    id="calendarWeek"
                    placeholder="Calendar Week"
                    required
                  />
                </div>
              </div>
              <div v-show="activeTab == 1"><ChapterList :manga-id="newManga.id"/></div>
              <div v-show="activeTab == 2">
                <div class="form-group">
                  <label >Bölüm İsmi</label>
                  <input
                    
                 
                    placeholder="Bölüm İsmi"
                    required
                  />
                </div>
  
                <div class="form-group">
                  <label for="status">Status</label>
                  <select v-model="newManga.status" id="status">
                    <option value="OnGoing">OnGoing</option>
                    <option value="Completed">Completed</option>
                    <option value="Hiatus">Hiatus</option>
                  </select>
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <input
                    v-model="newManga.type"
                    id="type"
                    placeholder="Type"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-section flex flex-col w-[20%] h-[100%] gap-[10px]">
          <div class="right-section-one flex flex-row ">
            <button class="action-btn" @click="create">Create Manga</button>
            <button class="action-btn delete">Delete Manga</button>
          </div>
          <div class="right-section-two p-[10px]">
            <div class="form-group flex flex-col gap-[10px]">
              <img :src="newManga.coverImageUrl" />
  
              <input v-show="false"
                v-model="newManga.coverImageUrl"
                id="coverImageUrl"
                placeholder="Cover Image URL"
                required
              />
             <button  @click="open"> Seç</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        isShow: false,
        bakaUrl:"",
        activeTab:0,
        newManga: {
          title: '',
          status: '',
          alternative: '',
          published: '',
          magazines: '',
          type: '',
          rating: '',
          reviews: '',
          description: '',
          author: '',
          genres: '',
          coverImageUrl: '',
          calendarType: '',
          calendarDay: '',
          calendarWeek: ''
        }
      };
    },
    methods: {
      create() {
        this.$emit('create-manga', this.newManga);
        this.newManga = {
          title: '',
          status: '',
          alternative: '',
          published: '',
          magazines: '',
          type: '',
          rating: '',
          reviews: '',
          description: '',
          author: '',
          genres: '',
          coverImageUrl: '',
          calendarType: '',
          calendarDay: '',
          calendarWeek: ''
        };
      },
      
    otoDoldur() {
      const token = this.bakaUrl.split("/")[4];
      const name = this.bakaUrl.split("/")[5];
      console.log(token + name);
      axios
        .get("http://localhost:3000/api/scrapper/" + token + "/" + name, {})
        .then((response) => {
          this.otoManga = response.data;
          this.newManga.title = this.otoManga.title;
          this.newManga.coverImageUrl = this.otoManga.coverImageUrl;
          this.newManga.description = this.otoManga.description;
          this.newManga.alternative = this.otoManga.alternative;
          this.newManga.type = this.otoManga.type;
          this.newManga.genres = this.otoManga.genres;
          this.newManga.author = this.otoManga.author;
          this.newManga.magazines = this.otoManga.magazines;
          this.newManga.published = this.otoManga.published;
          this.newManga.rating = this.otoManga.rating;
          this.newManga.reviews = this.otoManga.reviews;

        console.log(this.newManga)
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
          //this.setUser(null);
        });
    },
    }
  };
  </script>
  <style scoped>
  .edit-manga-form {
    padding: 20px;
    background-color: #1c1c1c;
    color: white;
    border-radius: 10px;
  }
  
  
  .pagination-btn {
    background: #182335;
    color: white;
    width: 100%;
    border: #0056b3 1px solid;
    text-align: left;
  }
  .right-section-two{
    display: flex;
    justify-content: center;
    align-items: center;
    border: #0056b3 1px solid;
  }
  .right-section-one{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .pagination-btn.active {
    background: #0056b3;
  }
  .extra-detil-left {
    border: #0056b3 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
  }
  .extra-detil-right {
    border: #0056b3 1px solid;
    padding: 15px;
    min-height: 900px;
  }
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .action-btn{
    width: 50%;
    height: 100%;
    
  }
  .action-btn.delete{
  background-color: #B34620;
  
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
</style>