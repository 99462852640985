<template>
  <div class="trending-list" v-if="trendingMangas.length>0">
    <h2>Trending</h2>
    <ul>
      <li v-for="manga in trendingMangas" :key="manga.id">
        <div class="manga-item">
          <img :src="manga.coverImageUrl" alt="Manga Cover" />
          <div class="manga-info">
            <h3>{{ manga.title }}</h3>
            <p>Chap {{ manga.latestChapter }} · Vol {{ manga.latestVolume }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TrendingList',
  data() {
    return {
      trendingMangas: []
    };
  },
  created() {
    this.fetchTrendingMangas();
  },
  methods: {
    fetchTrendingMangas() {
      axios.get(process.env.VUE_APP_AXIOS+'/trending')
        .then(response => {
          this.trendingMangas = response.data;
        })
        .catch(error => {
          console.error('Error fetching trending mangas:', error);
        });
    }
  }
};
</script>

<style scoped>
.trending-list {
  background-color: #1c1c1c;
  color: white;
  padding: 20px;
  border-radius: 10px;
}



.trending-list ul {
  list-style-type: none;
  padding: 0;
}

.trending-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.manga-item {
  display: flex;
  gap: 10px;
}

.manga-item img {
  width: 50px;
  height: auto;
  border-radius: 5px;
}

.manga-info h3 {
  margin: 0;
  font-size: 16px;
}

.manga-info p {
  margin: 5px 0 0;
  color: #b0b0b0;
}
</style>
