<template>
  <div>
  
  <div class="admin-container">
   
    
     
      <router-view></router-view>
  
  </div>
  <div class="sidebar"><AdminSidebar></AdminSidebar></div>
</div>
</template>

<script>
import AdminSidebar from "../components/Admin/AdminSidebar/AdminSidebar.vue";

export default {
  name: "AdminView",
  components: {
    AdminSidebar,
  },
  data() {
    return {
      
    };
  }
};
</script>

<style scoped>
.admin-container {
  position: absolute;
  width: 90%;
  height: 90%  ;
  left: 140px;
  padding: 20px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
  display: flex;
 flex-direction: column;
 justify-items: flex-start;
 align-items: flex-start;
}
.sidebar{

}
nav {
  margin-bottom: 20px;
}

nav a {
  margin-right: 10px;
  color: #007bff;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}
</style>
