<template>
  <div class="w-full">
    <div class="flex flex-row justify-between">
    <h2>Existing Mangas</h2>
    <h3 v-if="sliders.length < 1">No existing Manga</h3>
    <div><Icon icon="zondicons:add-solid" width="2em" height="2em"  style="color: white" /></div>
  </div>
      <EasyDataTable
   
    v-model:items-selected="itemsSelected"
     theme-color="#1d90ff"
    table-class-name="customize-table"
    buttons-pagination
    :headers="headers"
    :items="sliders"
    :loading="loading"
       :sort-by="sortBy"
    :sort-type="sortType"
    
  >
  
  <template #item-description="item">
    <div class="operation-wrapper">
     <p class="max-h-[20px] max-w-[100px] truncate">{{item.description}}</p>
    </div>
  </template>

  <template #item-genre="item">
    <div class="operation-wrapper">
     <p v-for="it in item" :key="it" >{{ it }}</p>
    </div>

  </template>

      <template #loading>
        <img
          src="https://giphy.com/embed/WiIuC6fAOoXD2"
          style="width: 60px; height: 100px"
        />
      </template>
      
      <template #item-operation="item">
        <div class="operation-wrapper flex flex-row">
         
          <Icon
            icon="material-symbols:edit"
            width="2em"
            height="2em"
            style="color: white"
            class="operation-icon"
              @click="editItem(item)"
          />
      
        </div>
      </template>
  </EasyDataTable>
    
  </div>
</template>




<script>
import EasyDataTable  from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import { Icon } from "@iconify/vue";
export default {
  components: {
    EasyDataTable,Icon,
  },
  data() {
    return {
     
 sortBy :"id",
 sortType:  "asc",
       
      headers: [
        { text: "Operation", value: "Operation" },
        { text: "id", value: "id", sortable: true },
        { text: "title", value: "title", sortable: true },
        { text: "type", value: "type" },
        { text: "status", value: "status" },
        { text: "description", value: "description" },
        { text: "genres", value: "genres",type: "object" },
        { text: "coverImageUrl", value: "coverImageUrl" },
        
     
      ],
    };
  },

  props: {
    sliders: {
      type: Array,
      required: true,
    },
  },
  methods: {
   

     editItem(val){
      this.$emit('edit-slider', val)
    }
  },
  created() {
   
  },
};
</script>
<style scoped>
.customize-table {
  --easy-table-border: 1px solid #445269;
  --easy-table-row-border: 1px solid #445269;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: white;
  --easy-table-body-row-hover-background-color: #0D204F;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
  
}
</style>
