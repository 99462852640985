<template>
  <div class="slider-container">
    <button @click="prevSlide" class="nav-btn prev-btn">‹</button>
    <div class="slider-wrapper">
      <div
        @enter="stopAutoSlide"
        class="slider"
        :style="{
          transform: `translateX(-${currentIndex * (100 / slidesPerView)}%)`,
        }"
      >
        <div class="Ada"
          v-for="slider in sliders"
          :key="slider"
          @click="viewManga(slider.seriesId)"
        >
          <div class="slide"
            v-for="item in mangas"
            :key="item"
            v-show="item.id == slider.seriesId"
          >
            <div class="slide-info" v-if="item.id == slider.seriesId">
              <div class="status">
                <h4>{{ item.status }}</h4>
              </div>
              <div class="title">
                <h3>{{ item.title }}</h3>
              </div>

              <div class="description">
                <p>{{ item.description }}</p>
              </div>
              <div
                class="chap"
                v-for="(chap, index) in chapters"
                :key="chap"
                v-show="item.mangaID == chap.seriesId"
              >
                <p>
                  <span>{{ chap.title }}</span> - Vol {{ index }}
                </p>
              </div>

              <div class="genres">
                <span v-for="(genre, i) in item.genres" :key="i"
                  ><div v-if="i < 3">{{ genre }}</div></span
                >
              </div>
            </div>
            <div class="photo" v-if="item.id == slider.seriesId">
              <img class="z-[99]" :src="item.coverImageUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="nextSlide" class="nav-btn next-btn">›</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SliderComp",

  data() {
    return {
      currentIndex: 0,
      visible: false,
    };
  },

  computed: {
    ...mapGetters(["sliders"]),
    ...mapGetters(["mangas"]),

    totalSlides() {
      if (window.innerWidth <= 600) {
        return Math.ceil(this.sliders.length);
      } else if (window.innerWidth <= 900) {
        return Math.ceil(this.sliders.length - 1);
      } else {
        return Math.ceil(this.sliders.length - 2);
      }
    },

    slidesPerView() {
      if (window.innerWidth <= 600) {
        return 1;
      } else if (window.innerWidth <= 900) {
        return 2;
      } else {
        return 3;
      }
    },
  },
  methods: {
    ...mapActions(["fetchSliders", "fetchMangas"]),

    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.totalSlides;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.totalSlides) % this.totalSlides;
    },
    handleResize() {
      this.currentIndex = 0; // Resize işlemi olduğunda indexi sıfırlıyoruz
    },
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 5000);
    },
    stopAutoSlide() {
      clearInterval(this.interval);
    },
    viewManga(id) {
      this.$router.push(`/manga/${id}`);
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);

    await this.fetchSliders();
    await this.fetchMangas();
   
    this.startAutoSlide();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.stopAutoSlide();
  },
};
</script>

<style scoped>
.slider-container {
  position: absolute;
  right: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.slider-wrapper {
  width: 100%;
  margin-left: 50px;
  margin-right: 50px;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
  padding-right: 24px;
  padding-left: 5px;
  gap: 10px;
}
.Ada{
  flex: 0 0 calc(100% /3);
  
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.slide {
  flex: 0 0 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 5px;

  height: 15rem;
  -webkit-box-shadow: -5px 0px 0px 0px aqua;
  -moz-box-shadow: -5px 0px 0px 0px aqua;
  box-shadow: -5px 0px 0px 0px aqua;
  background: #182335;
  border-radius: 10px;
  overflow: hidden;
}
.title {
  color: white;
}
.status {
  color: #51a6dd;
  font-size: 1.2rem;
  opacity: 0.5;
}
.description {
  position: relative;
  width: 80%;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}
.chap {
  color: white;
  opacity: 0.5;
}
.description p {
  color: white;
  opacity: 0.5;
  height: 50px;
}
.photo {
  position: relative;
  left: 20px;
  top: 60px;
  opacity: 0.6;
  rotate: 15deg;
  width: auto;
  scale: 1.8;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.slide:hover > .photo {
  position: relative;
  right: 20px;
  left: 0px;
  top: 30px;
  opacity: 1;
  scale: 1.3;
  rotate: 0deg;

  transition: all 0.3s;
}
@media (max-width: 900px) {
  .slide {
    flex: 0 0 calc(100%); /* 2 ögeyi yan yana gösterir */
  }
  .Ada{
    flex: 0 0 calc(100%/2);
  }
}


@media (max-width: 600px) {
  .Ada {
    flex: 0 0 100%; /* 1 ögeyi yan yana gösterir */
  }
}

.slide img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.slide-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 70%;
}

.slide-info h3 {
  margin: 0 0 10px;
}

.slide-info p {
  margin: 5px 0;
}

.genres {
  display: flex;
  gap: 10px;
}

.genres span {
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  color: #89b8db;
}
.genres span:hover {
  color: #3370a2;
  cursor: pointer;
}
.nav-btn {
  background-color: #3c8bc6;
  border: none;
  color: white;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-btn {
  left: 0px;
  border-radius: 0px 10px 10px 0px;
  transition: 0.5s ease all;
}
.prev-btn:hover {
  width: 40px;
}
.next-btn {
  right: 0px;
  border-radius: 10px 0px 0px 10px;
  transition: all 0.3s;
}
.next-btn:hover {
  width: 40px;
}
</style>
