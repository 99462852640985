<template>
  <div class="recently-updated">
    <div class="recently-header">
      <div class="title">Recently Updated</div>
      <div class="filter-options">
        <button :class="{ active: filterMode === 'all' }" @click="setFilterMode('all')">All</button>
        <button v-if="mangaTypeCounter" :class="{ active: filterMode === 'manga' }" @click="setFilterMode('manga')">Manga</button>
        <button v-if="manhwaTypeCounter"  :class="{ active: filterMode === 'manhwa' }" @click="setFilterMode('manhwa')">Manhwa</button>
        <button v-if="manhuaTypeCounter"  :class="{ active: filterMode === 'manhua' }" @click="setFilterMode('manhua')">Manhua</button>
        <button v-if="mangaTypeCounter"  :class="{ active: filterMode === 'trending' }" @click="setFilterMode('trending')">Trending</button>
      </div>
    </div>
    <div class="manga-grid">
      <MangaCard v-for="(manga, index) in filteredMangas" :key="index" :volumes="volumes" :chapters="chapters" :manga="manga" />
    </div>
  </div>
</template>

<script>
import MangaCard from '../MangaCard/MangaCard.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'RecentlyUpdated',
  components: {
    MangaCard
  },
  data() {
    return {
      filterMode: 'all',
      mangaCount:0,
      manhuaCount:0,
      manhwaCount:0,
     
    };
  },
  async created(){
  await  this.fetchMangas();
   await this.fetchVolume();
  await  this.fetchChapters();
 
  },
  computed: {
    ...mapGetters(['mangas','volumes','chapters']),
    mangaTypeCounter() {
    return (this.mangas.filter(manga => manga.type.toLowerCase() === "manga").length);
    },
    manhwaTypeCounter() {
    return (this.mangas.filter(manga => manga.type.toLowerCase() === "manhwa").length);
    },
    manhuaTypeCounter() {
    return (this.mangas.filter(manga => manga.type.toLowerCase() === "manhua").length);
    },
    filteredMangas() {
      if (this.filterMode === 'all') {
        return this.mangas;
      }
      return this.mangas.filter(manga => manga.type.toLowerCase() === this.filterMode);
    }
  },
  methods: {
    ...mapActions(['fetchMangas','fetchVolume','fetchChapters']),
    setFilterMode(mode) {
      this.filterMode = mode;
    },
   
  }
};
</script>

<style scoped>
.recently-updated {
  padding: 30px;
  background-color: transparent;
  color: white;
  border-radius: 10px;
}
.title{
  font-size: 1.8rem;
  font-family: Poppins, sans-serif;
  color: #CFD1D4;
}
.recently-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-options {
  display: flex;
  gap : 10px;
}

.filter-options button {
  background: none;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.filter-options button.active {
  background-color: #007bff;
  border-radius: 5px;
}

.manga-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 15px;
}

@media (min-width: 1200px) {
  .manga-grid {
    grid-template-columns: repeat(3, 1fr); /* En büyük ekranlarda 3 kart */
  }
}

@media (max-width: 900px) {
  .manga-grid {
    grid-template-columns: repeat(2, 1fr); /* Orta ekranlarda 2 kart */
  }
  .filter-options{
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .manga-grid {
    grid-template-columns: repeat(1, 1fr); /* Küçük ekranlarda 1 kart */
  }
}
</style>
