<template>
  <div>
    <h1>Register</h1>
    <form @submit.prevent="register">
      <input v-model="username" placeholder="Username" />
      <input v-model="email" placeholder="Email" />
      <input v-model="password" type="password" placeholder="Password" />
      <button type="submit">Register</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      message: ''
    };
  },
  methods: {
    register() {
      axios.post(process.env.VUE_APP_AXIOS+'/auth/register', {
        username: this.username,
        email: this.email,
        password: this.password
      })
      .then(response => {
        this.message = response.data.message;
        console.log(response.data.message)
        // Doğrulama kodu giriş sayfasına yönlendir
        this.$router.push('/verify-email');
       //this.$router.push('/login');
      })
      .catch(error => {
        console.error(error);
        this.message = 'An error occurred. Please try again.';
      });
    }
  }
};
</script>

<style scoped>
/* Stil eklemeleri */
</style>
