<template>
  <div v-if="localEditMangaData" class="edit-manga-form">
    <h2>Edit Manga</h2>
    <div class="flex flex-row w-full gap-[20px]">
      <div class="left-section flex flex-col w-[80%] h-[100%] gap-[20px]">
        <div class="form-group w-[100%]">
          <input
            v-model="localEditMangaData.title"
            id="title"
            placeholder="Title"
            class="text-white"
            required
          />
        </div>
        <div>
          <router-link :to="'/manga/' + localEditMangaData.id"
            >Kalıcı Bağlantı: http://localhost:8080/manga/{{
              localEditMangaData.id
            }}</router-link
          >
        </div>
        <div class="form-group">
          <textarea
            v-model="localEditMangaData.description"
            id="description"
            placeholder="Description"
            class="h-[200px] text-white"
            required
          ></textarea>
        </div>
        <div class="extra-detil box-content flex flex-row">
          <div class="extra-detil-left w-[20%]">
            <button
              class="pagination-btn"
              :class="{ active: activeTab == 0 }"
              @click="activeTab = 0"
            >
              Extra Detail
            </button>
            <button
              class="pagination-btn"
              :class="{ active: activeTab == 1 }"
              @click="activeTab = 1"
            >
              Chapter List
            </button>
            <button
              class="pagination-btn"
              :class="{ active: activeTab == 2 }"
              @click="activeTab = 2"
            >
              Upload Chapter
            </button>
            <button
              class="pagination-btn"
              :class="{ active: activeTab == 3 }"
              @click="activeTab = 3"
            >
              Upload Chapters
            </button>
          </div>
          <div class="extra-detil-right w-[80%]">
            <div v-show="activeTab == 0">
              <div class="flex gap-5 flex-row w-full justify-center form-group">
                <input class="w-[80%]" v-model="bakaUrl" />
                <button @click="otoDoldur">Oto Doldur</button>
              </div>
              <div class="form-group">
                <label for="alternative">Alternative Name</label>
                <input
                  v-model="localEditMangaData.alternative"
                  id="alternative"
                  placeholder="Alternative Name"
                  required
                />
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select v-model="localEditMangaData.status" id="status">
                  <option value="OnGoing">OnGoing</option>
                  <option value="Completed">Completed</option>
                  <option value="Hiatus">Hiatus</option>
                </select>
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <input
                  v-model="localEditMangaData.type"
                  id="type"
                  placeholder="Type"
                  required
                />
              </div>

              <div class="form-group">
                <label for="rating">Rating</label>
                <input
                  v-model="localEditMangaData.rating"
                  id="rating"
                  placeholder="Rating"
                  required
                />
              </div>

              <div class="form-group">
                <label for="reviews">Reviews</label>
                <input
                  v-model="localEditMangaData.reviews"
                  id="reviews"
                  placeholder="Reviews"
                  required
                />
              </div>

              <div class="form-group">
                <label for="magazines">Magazines</label>
                <input
                  v-model="localEditMangaData.magazines"
                  id="magazines"
                  placeholder="Magazines"
                  required
                />
              </div>

              <div class="form-group">
                <label for="author">Author</label>
                <input
                  v-model="localEditMangaData.author"
                  id="author"
                  placeholder="Author"
                  required
                />
              </div>

              <div class="form-group">
                <label for="genres">Genres</label>
                <input
                  v-model="localEditMangaData.genres"
                  id="genres"
                  placeholder="Genres"
                  required
                />
              </div>

              <div class="form-group">
                <label for="published">Published</label>
                <input
                  v-model="localEditMangaData.published"
                  id="published"
                  placeholder="Published"
                  required
                />
              </div>

              <div class="form-group">
                <label for="calendarType">Calendar Type</label>
                <input
                  v-model="localEditMangaData.calendarType"
                  id="calendarType"
                  placeholder="Calendar Type"
                  required
                />
              </div>

              <div class="form-group">
                <label for="calendarDay">Calendar Day</label>
                <input
                  v-model="localEditMangaData.calendarDay"
                  id="calendarDay"
                  placeholder="Calendar Day"
                  required
                />
              </div>

              <div class="form-group">
                <label for="calendarWeek">Calendar Week</label>
                <input
                  v-model="localEditMangaData.calendarWeek"
                  id="calendarWeek"
                  placeholder="Calendar Week"
                  required
                />
              </div>
            </div>
            <div v-show="activeTab == 1">
              <ChapterList :manga-id="localEditMangaData.id" />
            </div>
            <div v-show="activeTab == 2">
              <div class="form-group">
                <label for="volume">Volume:</label>
                <div class="flex">
                  <select v-model="volumeId" id="volume">
                    <option v-for="volume in volumes" :value="volume.id" :key="volume">{{volume.title}}</option>
                    
                  </select>
                  
                  <Icon icon="carbon:add-filled" width="2em" height="2em"  style="color: white" @click="volumeAdd=!volumeAdd" />
                </div>
                  <div class="flex" v-show="volumeAdd">
                  <label>Volume Name:</label>
                  <input placeholder="Volume Name:" required v-model="volumeTitle">
                  <button @click="addVolume">Cilt Ekle</button>
                
                </div>
                
              </div>
              <div class="form-group">
                <label>Bölüm No:</label>
                <input placeholder="Bölüm No" required v-model="ChapterID">
              </div>
              <div class="form-group">
                <label>Bölüm İsmi</label>
                <input placeholder="Bölüm İsmi" required v-model="ChapterTitle">
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select v-model="localEditMangaData.status" id="status">
                  <option value="OnGoing">OnGoing</option>
                  <option value="Completed">Completed</option>
                  <option value="Hiatus">Hiatus</option>
                </select>
              </div>

           
              <div class="form-group">
                <input type="file" @change="handleFileUpload" accept=".zip" />
                <button @click="uploadFile">Yükle</button>
                <Icon v-show="UploadQue" icon="line-md:loading-loop" width="2em" height="2em"  style="color: white" />
                <Icon v-show="UploadFault" icon="mingcute:fault-fill" width="2em" height="2em"  style="color: white" />
                <p v-if="uploadStatus">{{ uploadStatus }}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="right-section flex flex-col w-[20%] h-[100%] gap-[10px]">
        <div class="right-section-one flex flex-row">
          <button class="action-btn" @click="update">Update Manga</button>
          <button class="action-btn delete">Delete Manga</button>
        </div>
        <div class="right-section-two p-[10px]">
          <div class="form-group flex flex-col gap-[10px]">
            <img :src="localEditMangaData.coverImageUrl" />

            <input
              v-show="false"
              v-model="localEditMangaData.coverImageUrl"
              id="coverImageUrl"
              placeholder="Cover Image URL"
              required
            />
            <button @click="open">Seç</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { useFileDialog } from "@vueuse/core";
import ChapterList from "@/components/MangaDetail/ChapterList.vue";
import axios from "axios";
const { files, openDialog, open, reset, onChange } = useFileDialog({
  accept: "image/*", // Set to accept only image files
  directory: false, // Select directories instead of files if set true
  multiple: false,
});


onChange((files) => {
  /** do something with files */
  console.log(files[0].name);
});
</script>

<script>
import { mapGetters, mapActions } from "vuex";
import { Icon } from "@iconify/vue";
export default {

  components: {
    useFileDialog,
  },
  props: {
    editMangaData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      bakaUrl: "",
      otoManga: [],
      localEditMangaData: { ...this.editMangaData },
      selectedFile: null,
      uploadStatus: '',
      ChapterID:0,
      ChapterTitle:"",
      UploadQue:false,
      UploadFault:false,
      volumeId:0,
      SeriesID:0,
      volumeAdd:false,
      volumeTitle:"",


    };
  },
  watch: {
    editMangaData: {
      handler(newVal) {
        this.localEditMangaData = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
  computed:{
    ...mapGetters(["volumes"]),
  },
  created(){
    this.fetchVolumes(this.localEditMangaData.id);
  },
  methods: {
    ...mapActions(["fetchVolumes",'createVolume']),
    addVolume()
    {
      let volume={
        "title":this.volumeTitle,
        "seriesId":this.localEditMangaData.id
      }

      this.createVolume(volume).then(() => {
        this.volumeAdd=false;
      });
     
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
     console.log(this.localEditMangaData)
    },
    async uploadFile() {
      if (!this.selectedFile) {
        this.uploadStatus = 'Lütfen bir dosya seçin.';
        return;
      }

      try {
        const formData = new FormData();
        formData.append('title', this.ChapterTitle);
        formData.append('chapterNumber', this.ChapterID);
        formData.append('volumeId', this.volumeId);
        formData.append('seriesId', this.localEditMangaData.id);
        formData.append('mangaZip', this.selectedFile);  // ZIP dosyasını ekliyoruz
        this.UploadQue=true;
        this.uploadStatus='Uploading:'+ this.ChapterID;
        const response = await axios.post('http://localhost:3000/api/chapters/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.UploadQue=false;
        this.UploadFault=false;
        this.uploadStatus='Chapter created:'+ response.data;
        alert('Chapter created successfully!');
        
      } catch (error) {
        this.UploadFault=true;
        this.UploadQue=false;
        this.uploadStatus='Error creating chapter:'+ error;
        alert('Error creating chapter. Please try again.');
      }
    },

    update() {
      this.$emit("update-manga", this.localEditMangaData);
    },
    otoDoldur() {
      const token = this.bakaUrl.split("/")[4];
      const name = this.bakaUrl.split("/")[5];
      console.log(token + name);
      axios
        .get("http://localhost:3000/api/scraper/" + token + "/" + name, {})
        .then((response) => {
          this.otoManga = response.data;
          this.localEditMangaData.title = this.otoManga.title;
          this.localEditMangaData.coverImageUrl = this.otoManga.coverImageUrl;
          this.localEditMangaData.description = this.otoManga.description;
          this.localEditMangaData.alternative = this.otoManga.alternative;
          this.localEditMangaData.type = this.otoManga.type;
          this.localEditMangaData.genres = this.otoManga.genres;
          this.localEditMangaData.author = this.otoManga.author;
          this.localEditMangaData.magazines = this.otoManga.magazines;
          this.localEditMangaData.published = this.otoManga.published;
          this.localEditMangaData.rating = this.otoManga.rating;
          this.localEditMangaData = this.otoManga.reviews;

        
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
          //this.setUser(null);
        });
    },
  },
};
</script>

<style scoped>
.edit-manga-form {
  padding: 20px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
}

.pagination-btn {
  background: #182335;
  color: white;
  width: 100%;
  border: #0056b3 1px solid;
  text-align: left;
}
.right-section-two {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #0056b3 1px solid;
}
.right-section-one {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group {
  color: white;
}
.pagination-btn.active {
  background: #0056b3;
}
.extra-detil-left {
  border: #0056b3 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}
.extra-detil-right {
  border: #0056b3 1px solid;
  padding: 15px;
  min-height: 900px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.action-btn {
  width: 50%;
  height: 100%;
}
.action-btn.delete {
  background-color: #b34620;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
