<template>
    <div class="manga-create-view">
      <h1>Create New Manga</h1>
      <form @submit.prevent="createManga">
        <div class="form-group">
          <label for="title">Title</label>
          <input v-model="title" id="title" type="text" required />
        </div>
        <div class="form-group">
          <label for="author">Author</label>
          <input v-model="author" id="author" type="text" required />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea v-model="description" id="description"></textarea>
        </div>
        <div class="form-group">
          <label for="coverImageUrl">Cover Image URL</label>
          <input v-model="coverImageUrl" id="coverImageUrl" type="text" />
        </div>
        <div class="form-group">
          <label for="published">Published</label>
          <input v-model="published" id="published" type="text" />
        </div>
        <div class="form-group">
          <label for="genres">Genres (comma separated)</label>
          <input v-model="genres" id="genres" type="text" />
        </div>
        <div class="form-group">
          <label for="magazines">Magazines</label>
          <input v-model="magazines" id="magazines" type="text" />
        </div>
        <div class="form-group">
          <label for="rating">Rating</label>
          <input v-model="rating" id="rating" type="number" step="0.1" />
        </div>
        <div class="form-group">
          <label for="reviews">Reviews</label>
          <input v-model="reviews" id="reviews" type="number" />
        </div>
        <button type="submit">Create Manga</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'MangaCreateView',
    data() {
      return {
        title: '',
        author: '',
        description: '',
        coverImageUrl: '',
        published: '',
        genres: '',
        magazines: '',
        rating: '',
        reviews: ''
      };
    },
    methods: {
      createManga() {
        const mangaData = {
          title: this.title,
          author: this.author,
          description: this.description,
          coverImageUrl: this.coverImageUrl,
          published: this.published,
          genres: this.genres.split(',').map(genre => genre.trim()),
          magazines: this.magazines,
          rating: parseFloat(this.rating),
          reviews: parseInt(this.reviews, 10)
        };
  
        axios.post(process.env.VUE_APP_AXIOS+'/mangas/create-manga', mangaData)
          .then(response => {
            console.log('Manga created:', response.data);
           // this.$router.push('/');
           
          })
          .catch(error => {
            console.error('Error creating manga:', error);
           
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .manga-create-view {
    padding: 20px;
    background-color: #1c1c1c;
    color: white;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  