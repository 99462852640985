
<template>
    <div>
      <h1>Login</h1>
      <form @submit.prevent="login">
        <input v-model="username" placeholder="Username" />
        <input v-model="password" type="password" placeholder="Password" />
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  //import store from '../store';
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      login() {
       axios.post(process.env.VUE_APP_AXIOS+'/auth/login', {
        username: this.username,
          password: this.password
        })
        .then(response => {
          localStorage.setItem('token', response.data.token);
         
          this.$router.push('/admin');
        })
        .catch(error => {
          console.error(error);
        
        });
      }
    },
    
  created() {
   if(localStorage.getItem("token")!=null) this.$router.push('/admin');
  }
  
  };
  </script>
  