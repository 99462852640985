<template>
    <header class="header">
      <div class="logo">
        <img src="https://araznovel.com/wp-content/uploads/2023/08/logo-2-e1692962095440.png" alt="Logo" />
        <span>Manga Fire.to</span>
      </div>
      <nav class="nav-links">
        <router-link to="/">Types</router-link>
        <router-link to="/genres">Genres</router-link>
        <router-link to="/newest">Newest</router-link>
        <router-link to="/updated">Updated</router-link>
        <router-link to="/added">Added</router-link>
        <router-link to="/random">Random</router-link>
      </nav>
      <div class="search-login">
        <input type="text" placeholder="Search manga..." />
        <button class="filter-btn">Filter</button>
        <router-link to="/login" class="login-btn">Login</router-link>
        <router-link to="/menu" class="menu-btn">Menu</router-link>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'TopbarMangaReader'
  };
  </script>
  
  <style scoped>
  .header {
    background-color: #0E1726;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 60px;
    color: #ffffff;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-right: 0.5rem;
  }
  
  .nav-links {
    display: flex;
    gap: 1rem;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .nav-links a.router-link-exact-active {
    font-weight: bold;
  }
  
  .search-login {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .search-login input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
  }
  
  .filter-btn, .login-btn, .menu-btn {
    background-color: #007bff;
    border: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .filter-btn:hover, .login-btn:hover, .menu-btn:hover {
    background-color: #0056b3;
  }
  </style>
  