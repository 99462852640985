<template>
  <div class="manga-detail-view">
    <MangaDetail :manga="mangas" v-if="mangas" />
    <div class="main-content">
      <div style="width: 80%; height: 100%">
        <ChapterList :mangaId="mangaId" v-if="mangas" />
      </div>
      <div style="width: 20%;">
        <TrendingList />
      </div>
    </div>
    <div class="disqus-wrapper">
      <Disqus />
    </div>
  </div>
</template>

<script>
import MangaDetail from '../components/MangaDetail/MangaDetail.vue';
import ChapterList from '../components/MangaDetail/ChapterList.vue';
import TrendingList from '../components/MangaDetail/TrendingList.vue';
import { Disqus } from 'vue-disqus';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MangaDetailView',
  components: {
    MangaDetail,
    ChapterList,
    TrendingList,
    Disqus
  },
  data() {
    return {
      mangaId: null,
    };
  },
  computed: {
    ...mapGetters(['mangas'])
  },
  created() {
    this.mangaId = this.$route.params.id;
    this.fetchManga(this.mangaId);
  },
  methods: {
    ...mapActions(['fetchManga']),
  }
};
</script>

<style scoped>
.manga-detail-view {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-content {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 10px;
  color: white;
  padding: 20px;
  border-radius: 10px;
}
</style>

<!-- Global CSS -->
<style>
.disqus-wrapper * {
  all: unset;
}

.disqus-wrapper div, 
.disqus-wrapper span, 
.disqus-wrapper a, 
.disqus-wrapper p, 
.disqus-wrapper img {
  all: initial;
  color: inherit; /* Eğer yazı renginin miras alınmasını istiyorsanız */
  font:inherit;
}
</style>
