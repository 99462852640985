import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '@/components/LoginView.vue';
import Register from '@/components/RegisterView.vue';
import MangaList from '@/components/MangaList.vue';
import MangaReader from '@/components/MangaReader.vue';
import Admin from '@/views/AdminView.vue';
import ManageMangas from '@/components/Admin/ManageMangas.vue'
import ManageUsers from '@/components/Admin/ManageMangas.vue'
import VerifyEmail from '../components/VerifyEmail.vue';
import MangaDetailView from '../views/MangaDetailView.vue'
import MangaCreateView from '../views/MangaCreate.vue';
import ChapterCreateView from '../views/ChapterCreateView.vue';
import ChapterReadView from '../views/ChapterReadView.vue';
import UserProfile from '../views/UserProfilView.vue'
import UserList from "../views/UserList.vue";
import MangaManagement from '../views/MangaManagementView.vue'
import MangaCalendarView from '../views/MangaCalendarView.vue'
import SliderSetup from '../components/Admin/SliderSetup/SliderSetup.vue'
import NotFound from '../views/NotFound.vue'

import store from '../store';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/verify-email', name: 'VerifyEmail', component: VerifyEmail },
    { path: '/mangas', component: MangaList },
    { path: '/manga-reader/:id', component: MangaReader } ,
    { path: '/MangaCalendar', component: MangaCalendarView } ,
    { path: '/admin', name: 'Admin', component: Admin, 
      children: [
        { path: 'mangas', component: ManageMangas },
        { path: 'users', component: ManageUsers },
        { path: 'create-manga', name: 'CreateManga', component: MangaCreateView }, 
        { path: 'create-chapter', name: 'CreateChapter', component: ChapterCreateView },
        { path: "profile/:id?", name: "UserProfile", component: UserProfile },
        { path: 'manga-management', name: 'MangaManagement', component: MangaManagement },
        { path: "user-list", name: "UserList", component: UserList },
        { path: "slider-setup", name: "SliderSetup", component: SliderSetup },
      ],
      meta: { requiresAuth: true } // Auth gerektiren rotayı belirle
    },
    { path: '/manga/:id', name: 'MangaDetailView', component: MangaDetailView,
      children:[

      ]
     },
     { path: '/read/:mangaId/chapter/:chapterId', name: 'ChapterReadView', component: ChapterReadView },
     { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global guard ekle
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Eğer route auth gerektiriyorsa ve kullanıcı authenticated değilse
    if ( localStorage.getItem("token")==null && !store.state.isAuthenticated) {
      next('/login'); // Login sayfasına yönlendir
    } else {
      next(); // Devam et
    }
  } else {
    next(); // Devam et
  }
});
export default router
