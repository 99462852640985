<template>
  <div class="chapter-read-view">
    <div class="topbar">
      <!-- Topbar içeriği burada -->
    </div>
    <div class="main-content">
      <div class="chapter-content">
        <img v-for="(page,index) in pages" :key="page" :src="require('@/assets'+page)" :alt="'Page ' + index" />
      </div>
      <div class="sidebar">
        <div class="chapter-info">
          <h2>{{ mangaTitle }}</h2>
          <p>You are reading by chapter</p>
          <div class="navigation">
            <label for="pageSelect">Page</label>
            <select id="pageSelect" v-model="currentPage">
              <option v-for="page in pages" :value="page.pageNumber" :key="page.pageNumber">{{ page.pageNumber }}</option>
            </select>
            <label for="chapterSelect">Chapter</label>
            <select id="chapterSelect" v-model="currentChapter">
              <option v-for="chapter in chapters" :value="chapter.id" :key="chapter.id">{{ chapter.title }}</option>
            </select>
          </div>
          <!-- Diğer sidebar bileşenleri -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChapterReadView',
  data() {
    return {
      mangaTitle: '',
      pages: [],
      pagesSt:"",
      chapters: [],
      currentPage: 1,
      currentChapter: this.$route.params.chapterId
    };
  },
  created() {
    this.fetchChapter();
 
  },
  methods: {
    fetchChapter() {
      const {  chapterId } = this.$route.params;
      axios.get(process.env.VUE_APP_AXIOS+`/chapters/chapter/${chapterId}`)
        .then(response => {
          this.mangaTitle = response.data.mangaTitle;
          this.pagesSt = response.data[0].Pages[0].pageContent;
          this.chapters = response.data.chapters;
          this.pages = this.pagesSt.replace("[","").replace("]","").replace("\\","").replace("\\","").replace(/"/g,"").replace(/\\/g,"").split(",")
         console.log(this.pages)
        })
        .catch(error => {
          console.error('Error fetching chapter:', error);
        });
    }
  },
  watch: {
    currentChapter(newChapterId) {
      this.$router.push({ name: 'ChapterReadView', params: { mangaId: this.$route.params.mangaId, chapterId: newChapterId } });
      this.fetchChapter();
    }
  }
};
</script>

<style scoped>
.chapter-read-view {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  color: white;
}

.topbar {
  background-color: #1c1c1c;
  padding: 10px;
  color: white;
}

.main-content {
  display: flex;
  padding: 20px;
}

.chapter-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapter-content img {
  width: 80%;
  margin-bottom: 20px;
}

.sidebar {
  flex: 1;
  background-color: #282828;
  padding: 20px;
  border-radius: 10px;
}

.chapter-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
