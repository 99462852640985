<template>
    <div class="user-profile">
      <h1>User Profile</h1>
   
      <div v-if="user">
        <div class="avatar-area">
        <img class="avatar" :src="user.avatarUrl" alt="User Avatar" v-if="user.avatarUrl" />
      </div>
        <form @submit.prevent="updateProfile">
          <label for="username">Username:</label>
          <input type="text" v-model="user.username" id="username" required />
  
          <label for="email">Email:</label>
          <input type="email" v-model="user.email" id="email" required />
  
          <label for="password" v-if="userLevel >= 5">Password:</label>
          <input type="password" v-model="user.password" id="level" v-if="userLevel >= 5" required />
  
          <label for="level" v-if="userLevel >= 5">Level:</label>
          <input type="number" v-model="user.level" id="level" v-if="userLevel >= 5" required />
  
          <label for="avatarUrl">Avatar URL:</label>
          <input type="text" v-model="user.avatarUrl" id="avatarUrl" />
  
          <button type="submit">Update Profile</button>
        </form>
      </div>
      <div v-else>
        Loading...
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';

  import axios from 'axios';
  
  export default {
    components: {
      
    },
    data() {
      return {
        userLevel: 0
      };
    },
    computed: {
      ...mapGetters(['user'])
    },
    created() {
      this.fetchSpecUser(this.$route.params.id);
      this.userLevel=5;
     
    },
    methods: {
      ...mapActions(['fetchSpecUser']),
   
      updateProfile() {
        const token = localStorage.getItem('token');
        axios.put(process.env.VUE_APP_AXIOS+`/users/${this.user.id}`, this.user, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          alert('Profile updated successfully!');
          console.log(response)
        })
        .catch(error => {
          console.error('Error updating profile:', error);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .user-profile {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1c1c1c;
    color: white;
    border-radius: 10px;
  }
  .avatar-area{
    width: 3rem;
    height: 3rem;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .avatar{
    width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;
  }
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: none;
    border-radius: 4px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  