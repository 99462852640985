<template>
    <div>
      <h1>Manga List</h1>
      <ul>
        <li v-for="manga in mangas" :key="manga.id">{{ manga.title }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        mangas: []
      };
    },
    created() {
      this.fetchMangas();
    },
    methods: {
      fetchMangas() {
        axios.get(process.env.VUE_APP_AXIOS+'/mangas')
          .then(response => {
            this.mangas = response.data;
          })
          .catch(error => {
            console.error("There was an error fetching the mangas!", error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Stil eklemek isterseniz buraya yazabilirsiniz */
  </style>
  