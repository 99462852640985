<template>
    <header class="header">
      <div class="logo">
        <img src="https://araznovel.com/wp-content/uploads/2023/08/logo-2-e1692962095440.png" alt="Logo" />
     
      </div>
      <nav class="nav-links">
        <router-link to="/">Types</router-link>
        <router-link to="/manga-reader/1">Genres</router-link>
        <router-link to="/newest">Newest</router-link>
        <router-link to="/updated">Updated</router-link>
        <router-link to="/added">Added</router-link>
        <router-link to='/mang  a/'>Random</router-link>
      </nav>
      <div class="search-login">
        <input class="search-input" type="text" placeholder="Search manga..." />
        <button class="filter-btn">Filter</button>
      
      </div>
      
      <router-link to="/login" class="login-btn" v-if="!isAuthenticated">Login</router-link>
      <router-link to="/login" class="login-btn" v-if="isAuthenticated" >Admin</router-link>

    </header>
  </template>
  
  <script setup>

import { mapGetters } from "vuex";
</script>
<script>
export default {
  name: 'TopbarDefault',
  computed: {
    ...mapGetters(["isAuthenticated"]),
   
  },
  created() {
    
    
  },
  methods: {
   
  },
};
</script>
  
  <style scoped>
  .header {
    background-color: #0E1726;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 60px;
    color: #ffffff;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-right: 0.5rem;
  }
  
  .nav-links {
    display: flex;
    gap: 1rem;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .nav-links a.router-link-exact-active {
    font-weight: bold;
  }
  .search-input{
    width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
    background-color: #182335;
 
    background-size: 10%;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding-left: 40px;
   
  }
  .search-login {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .search-login input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
  }
  
  .filter-btn, .login-btn {
    background-color: #007bff;
    border: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .filter-btn:hover, .login-btn:hover {
    background-color: #0056b3;
  }

  
@media (max-width: 900px) {

  


}
  </style>
  