<template>
  <div class="chapter-create">
    <h1>Create New Chapter</h1>
    <form @submit.prevent="createChapter">
      <label for="title">Title:</label>
      <input type="text" v-model="title" id="title" required />

      <label for="chapterNumber">Chapter Number:</label>
      <input type="number" v-model="chapterNumber" id="chapterNumber" required />

      <label for="mangaId">Manga ID:</label>
      <input type="number" v-model="mangaId" id="mangaId" required />

      <button type="submit">Create Chapter</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChapterCreateView',
  data() {
    return {
      title: '',
      chapterNumber: '',
      mangaId: ''
    };
  },
  methods: {
    createChapter() {
      axios.post(process.env.VUE_APP_AXIOS+`/mangas/${this.mangaId}/chapters`, {
        title: this.title,
        chapterNumber: this.chapterNumber,
        mangaId:this.mangaId
      })
      .then(response => {
        alert('Chapter created successfully!');
        console.log(response)
        this.$router.push(`/manga/${this.mangaId}`);
      })
      .catch(error => {
        console.error('Error creating chapter:', error);
      });
    }
  }
};
</script>

<style scoped>
.chapter-create {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: none;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
