import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueDisqus from "vue-disqus";
import PrimeVue from "primevue/config";
import '../index.css'
import Aura from '@primevue/themes/aura';
import { createPinia } from 'pinia';

createApp(App)
.use(createPinia())
  .use(store)
  .use(router)
  .use(PrimeVue, {
    theme: {
        preset: Aura
    }
})
  .use(VueDisqus, { shortname: "birgaripnovel" })
  .mount("#app");
