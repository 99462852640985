<template>
  <div class="manga-calendar">
    <h1>Manga Release Calendar</h1>
    <vue-cal
      hide-view-selector
      :events="mangaReleaseEvents"
      today-button
      active-view="week"
      :time="false"
      :disable-views="['years', 'year', 'month', 'day']"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default {
  components: {
    VueCal,
  },
  computed: {
    ...mapGetters(["mangas"]),
    mangaReleaseEvents() {
      const events = [];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      this.mangas.forEach((manga) => {
        if (manga.calendarType === "weekly") {
          for (let j = 0; j < 12; j++) {
            for (let i = 0; i < 4; i++) {
              const eventDate = new Date(
                currentYear,
                j,
                1 + i * 7 + (manga.calendarDay - 1)
              );
              // const nextEventDate = new Date(currentYear, j, 1 + i * 7 + (manga.calendarDay ));

              events.push({
                start: eventDate,
                end: eventDate,
                content:
                  '<div class="group flex justify-items-center items-center gap-[5px]"><img class="w-[50px] rounded-[50px] group-hover:opacity-50" src="' +
                  manga.coverImageUrl +
                  '"></img><p class="opacity-0 group-hover:opacity-100 ">' +
                  manga.title +
                  "</p></div>",
              });
              console.log(eventDate);
            }
          }
        } else if (manga.calendarType === "monthly") {
          for (let j = currentMonth; j < 12; j++) {
            const eventDate = new Date(currentYear, j, manga.calendarDay);
            events.push({
              start: eventDate,
              end: eventDate,
              content:
                '<div class="group flex justify-items-center items-center gap-[5px]"><img class="w-[50px] rounded-[50px] hover:opacity-50" src="' +
                manga.coverImageUrl +
                '"></img><p class="opacity-0 group-hover:opacity-100">' +
                manga.title +
                "</p></div>",
            });
          }
        }
      });

      return events;
    },
  },
  created() {
    this.fetchMangas();
    var events = this.mangaReleaseEvents;
    console.log(events);
  },
  methods: {
    ...mapActions(["fetchMangas"]),
  },
};
</script>

<style scoped>
.manga-calendar {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
}

h1 {
  margin-bottom: 20px;
}
.manga-image {
  width: 50px;
  border-radius: 50px;
}
</style>
