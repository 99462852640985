import axios from 'axios';
const token = localStorage.getItem('token');

const apiClient = axios.create({
  baseURL: 'https://raspi-api.araznovel.com/api', // API'nin temel URL'si
  headers: {
    'Content-Type': 'application/json',
     'Authorization': `Bearer ${token}`
  },
});

export default apiClient;