<template>
  <div id="app">
    <header class="header" v-if=presenceTopbar>
      <component :is="currentTopbar"  />
    </header>
    <div class="content" v-if=presenceTopbar >
      <aside class="sidebar"></aside>
      <main class="main-content">
        <router-view />
      </main>
      <aside class="sidebar"></aside>
    </div>
    <div class="content-admin" v-if=!presenceTopbar >
     
      <main class="main-content">
        <router-view />
      </main>
    
    </div>
    <footer class="footer" v-if=presenceTopbar >
      <Footer/>
    </footer>
  </div>
</template>

<script setup>
import Footer from "@/components/FooterComp.vue";


</script>
<script>
import TopbarDefault from './components/TopBarDefault.vue';
import TopbarMangaReader from './components/TopbarMangaReader.vue';
export default {
  name: 'App',
  components: {
    TopbarDefault,
    TopbarMangaReader
  },
  computed: {
    currentTopbar() {
      return this.$route.path.includes('/read') ? 'TopbarMangaReader' : 'TopbarDefault';
    },
    presenceTopbar() {
    //return false;
      return this.$route.path.includes('/admin') ? false : true;
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
  background: #0D1626;;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {

  text-align: center;

  width: 100%;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
}

nav a.router-link-exact-active {
  font-weight: bold;
}

.content {
  display: flex;
  flex: 1;
  width: 100%;
}

.sidebar {
  width: 50px;
  background-color: #0D1626;;
  

  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 1rem;
  justify-content: center;
  align-items: center
}

.footer {
  background-color: #f8f9fa;
 
  text-align: center;
  border-top: 1px solid #dee2e6;
  width: 100%;
}
@media (max-width: 880px) {
  .main-content{
    justify-content: center;
    align-items: center
  }
}
</style>
