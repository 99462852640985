<template>
  <a @click="handleLogout"
    ><Icon
      icon="ri:logout-circle-line"
      width="2em"
      height="2em"
      style="color: white"
  /></a>
</template>

<script>
import { mapActions } from "vuex";
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
button {
  width: 3rem;
  height: 3rem;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
}
</style>
