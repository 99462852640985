<template>
  <div class="manga-detail-container">
    <!-- Arka plan resmi burada olacak -->
    <div
      class="background-image"
      :style="{ backgroundImage: `url(${manga.coverImageUrl})` }"
    ></div>

    <!-- Cam efektinin uygulanacağı içerik -->
    <div class="manga-detail">
      <div class="left-section">
        <img :src="manga.coverImageUrl" alt="Manga Cover" class="cover-image" />
      </div>
      <div class="right-section">
        <div class="header">
          <div class="status">
            <h3 class="text-[.75rem]">{{ manga.status }}</h3>
          </div>
          <h1 class="text-[1.5rem] text-left">{{ manga.title }}</h1>
          <div class="alternative-names">
            <p>{{ manga.alternative }}</p>
          </div>
          <div class="actions">
            <button class="read">START READING</button>
            <button class="bookmark">
              <div class="flex flex-row justify-center items-center">
                <p>BOOKMARK</p>
                <Icon
                  icon="material-symbols:bookmark"
                  width="1em"
                  height="1em"
                  style="color: white; margin: 3px"
                />
              </div>
            </button>
          </div>
          <div class="reviews">
            <div class="type">{{ manga.type }}</div>
            <div class="bookmark flex justify-center item-center">
              <Icon class="my-[3px]" icon="material-symbols:book" />
              <p>18,502</p>
            </div>
          </div>
          <div class="description">
            <div class="summary">
              <p class="text-ellipsis overflow-hidden">
                {{ manga.description }}
              </p>
            </div>
            <button class="show-more" @click="visible = true">
              Show More +
            </button>
            <Dialog
              v-model:visible="visible"
              modal
              header="Summary"
              :style="{ width: '50rem' }"
            >
              <span class="text-surface-500 dark:text-surface-400 block mb-8">
                <p>Alternatives:{{ manga.alternative }}</p>
                <p><br />Description:{{ manga.description }}</p>
              </span>
            </Dialog>
          </div>
        </div>
        <div class="details">
          <div class="strong">
            <p>Author:</p>
            <p class="text-white">{{ manga.author }}</p>
          </div>
          <div class="strong">
            <p>Published:</p>
            <p class="text-white">{{ manga.published }}</p>
          </div>
          <div class="flex flex-row gap-[10px] justify-start items-center">
            <div class="flex flex-row gap-[10px] flex-wrap">
              <p class="strong">Genres:</p>
              <div v-for="(genre, index) in manga.genres" :key="genre">
                <div class="flex flex-row">
                  <p>{{ genre }}</p>
                  <p v-if="index < manga.genres.length - 1">,</p>
                </div>
              </div>
            </div>
          </div>
          <div class="strong">
            Magazines:
            <p class="text-white">{{ manga.magazines }}</p>
          </div>
          <div
            class="rating rounded-xl w-max p-[5px] flex flex-col justify-start items-start "
          >
            <div class="flex flex-row">
              <star-rating
                :star-size="25"
                :increment="0.1"
                :rating="manga.rating / 2"
                :read-only="true"
                :rounded-corners="true"
                :border-width="4"
                :star-points="[
                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                  46, 19, 31, 17,
                ]"
              />
              <p>{{ manga.rating }}/10</p>
            </div>
            <p class="text-left">{{ manga.reviews }} Kullanıcı Oyladı</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import StarRating from "vue-star-rating";
import Dialog from "primevue/dialog";

export default {
  name: "MangaDetail",
  components: {
    Icon,
    StarRating,
    Dialog,
  },
  data() {
    return {
      visible: false,
    };
  },
  props: {
    manga: null, // Manga verisi buradan geliyor
    backgroundUrl: String, // Dinamik arka plan resmi URL'si
  },
};
</script>

<style scoped>
.manga-detail-container {
  position: relative;
  width: 100%;
  min-height: 100%; /* Tüm ekranı kaplayacak şekilde yüksekliği ayarlar */
  overflow: hidden;
}

/* Arka plan resmi */
.background-image {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* Tüm alanı kaplar */
  background-position: center; /* Ortalanır */
  filter: blur(5px); /* Arka planı bulanıklaştırır */
  z-index: 1; /* Arka planda kalmasını sağlar */
  opacity: 0.7; /* Hafif saydamlık */
}

/* Cam efekti */
.manga-detail {
  display: flex;
  gap: 20px;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  position: relative;
  z-index: 1;
  background: rgba(13, 22, 38, 0.5); /* Cam efekti için saydam arka plan */
  backdrop-filter: blur(7px); /* Cam efekti */
  -webkit-backdrop-filter: blur(7px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.left-section {
  flex: 2;
}

.right-section {
  display: flex;
  justify-items: center;
  align-items: center;
  flex: 10;
}

.cover-image {
  height: 100%;
  border-radius: 10px;
}

.header {
  margin-bottom: 20px;
  width: 60%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.status {
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #51a6dd;
}
.actions button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3c8bc6;
  color: white;
  cursor: pointer;
}
.actions .bookmark {
  background-color: #1e2c43;
}
.actions button:hover {
  background-color: #2a3e5d;
}
.actions .read:hover {
  background-color: #5a9dcf;
}
.alternative-names {
  text-align: start;
  opacity: 0.5;
  font-size: 0.85rem;
  height: 40px;

  overflow: hidden;
}

.strong {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #8f9695;
  gap: 5px;
}
.reviews {
  display: flex;
  gap: 25px;
  font-size: 1rem;
}
.summary {
  height: 100px;
  overflow: hidden;
}
.description {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 95%;
  font-size: 1rem;
  height: 90px;
  gap: 20px;
}

.show-more {
  background-color: transparent;
  color: white;
  border: transparent;
  text-align: start;
  font-size: 15px;
  text-decoration: underline;
  text-decoration-color: #8bbadd;
  text-decoration-thickness: 3px;
  cursor: pointer;
}
.details {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.details p {
  margin: 5px 0;
}

.rating {
  display: flex;
  align-items: center;
  background-color: #1A2539;
  padding: 10px;

}

.rating p {
  margin-right: 10px;
}

@media (max-width: 900px) {
  .manga-detail {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .right-section {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .details {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
</style>
