<template>
    <div class="manga-management">
      <h1>SliderSetup</h1>
     <NewSlider @create-slider="create" ></NewSlider>
      <SliderList :sliders="sliders" @edit-slider="edit"  v-if="!editMangaData" />
      </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
 import SliderList from '../SliderSetup/SliderList.vue'
import NewSlider from '../SliderSetup/NewSlider.vue'
  
  export default {
    components: {
        SliderList,NewSlider
    },
    data() {
      return {
        editSliderData: null
      };
    },
    computed: {
      ...mapGetters(['sliders'])
    },
    created() {
      this.fetchSliders();
    },
    methods: {
      ...mapActions(['fetchSliders', 'createSlider', 'updateSlider']),
      create(newSlider) {
        console.log(newSlider)
        this.createSlider(newSlider).then(() => {
          this.fetchSliders();
        });
      },
      edit(slider) {
        this.editSliderData = { ...slider };
      },
      update(updatedSlider) {
        this.updateSlider(updatedSlider).then(() => {
          this.editSliderData = null;
          this.fetchSliders();
        });
      },
     
    }
  };
  </script>
  
  <style scoped>
  .manga-management {
    width: 100%;
    padding: 20px;
    background-color: #1c1c1c;
    color: white;
    border-radius: 10px;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  form {
    margin-bottom: 20px;
  }
  
  input {
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  </style>
  