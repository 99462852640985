<template>
  <div>
    <h1>User List</h1>

    <div v-if="users">
      <EasyDataTable
        theme-color="#1d90ff"
        table-class-name="customize-table"
        buttons-pagination
        :headers="headers"
        :items="users"
      >
        <template #item-operation="item">
          <div class="operation-wrapper flex flex-row">
            <Icon
              icon="material-symbols:delete"
              width="2em"
              height="2em"
              style="color: white"
              class="operation-icon"
              @click="deleteItem(item.id)"
            />
            <Icon
              icon="material-symbols:edit"
              width="2em"
              height="2em"
              style="color: white"
              class="operation-icon"
              @click="editItem(item)"
            />
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { Icon } from "@iconify/vue";

export default {
  components: {
    EasyDataTable,
    Icon,
  },
  data() {
    return {
      headers: [
        { text: "Operation", value: "Operation" },
        { text: "id", value: "id", sortable: true },
        { text: "username ", value: "username", sortable: true },
        { text: "isVerified ", value: "isVerified" },
        { text: "level", value: "level" },
        { text: "avatarUrl", value: "avatarUrl" },
      ],

      userLevel: 0,
    };
  },
  computed: {
    ...mapGetters(["users", "user"]),
  },
  created() {
    this.fetchUsers();
    this.fetchUser();
    console.log(this.users)
   
  },
  methods: {
    ...mapActions(["fetchUsers", "fetchUser", "deleteUser"]),
    editItem(item) {
      this.$router.push("/admin/profile/" + item.id);
    },
    deleteItem(item) {
      this.deleteUser(item);
    },
  },
};
</script>

<style scoped>
.customize-table {
  --easy-table-border: 1px solid #445269;
  --easy-table-row-border: 1px solid #445269;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: white;
  --easy-table-body-row-hover-background-color: #0d204f;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;

  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
