import { createStore } from 'vuex';
import apiClient from '../services/axiosClient.js'; // Dosya yolunuza göre düzenleyin

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('token'),
    user: null,
    users: [],
    mangas: [],
    sliders: [],
    volumes: [],
    chapters: [],
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setMangas(state, mangas) {
      state.mangas = mangas;
    },
    setVolumes(state, volumes) {
      state.volumes = volumes;
    },
    setChapters(state, chapters) {
      state.chapters = chapters;
    },
    setSliders(state, sliders) {
      state.sliders = sliders;
    },
    addManga(state, manga) {
      state.mangas.push(manga);
    },
    addVolume(state, volume) {
      state.volumes.push(volume);
    },
    updateManga(state, updatedManga) {
      const index = state.mangas.findIndex(manga => manga.id === updatedManga.id);
      if (index !== -1) {
        state.mangas.splice(index, 1, updatedManga);
      }
    },
    deleteManga(state, mangaId) {
      state.mangas = state.mangas.filter(manga => manga.id !== mangaId);
    },
    deleteUser(state, userID) {
      state.users = state.users.filter(user => user.id !== userID);
    },
    addSlider(state, slider) {
      state.sliders.push(slider);
    },
    updateSlider(state, updatedSlider) {
      const index = state.sliders.findIndex(slider => slider.id === updatedSlider.id);
      if (index !== -1) {
        state.sliders.splice(index, 1, updatedSlider);
      }
    },
  },
  actions: {
    login({ commit }, token) {
      localStorage.setItem('token', token);
      commit('setAuthentication', true);
      apiClient.get('/admin/me')
        .then(response => {
          commit('setUser', response.data);
        })
        .catch(error => {
          console.error('Error fetching user:', error);
          commit('setUser', null);
        });
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      commit('setAuthentication', false);
      commit('setUser', null);
    },
    fetchUser({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        apiClient.get('/admin/me')
          .then(response => {
            commit('setUser', response.data);
          })
          .catch(error => {
            console.error('Error fetching user:', error);
            commit('setUser', null);
          });
      } else {
        commit('setUser', null);
      }
    },
    fetchUsers({ commit }) {
      apiClient.get('/user')
        .then(response => {
          commit('setUsers', response.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          commit('setUsers', null);
        });
    },
    fetchSpecUser({ commit }, id) {
      apiClient.get(`/users/${id}`)
        .then(response => {
          commit('setUser', response.data);
        })
        .catch(error => {
          console.error('Error fetching specific user:', error);
        });
    },
    fetchMangas({ commit }) {
      apiClient.get('/series')
        .then(response => {
          commit('setMangas', response.data);
        })
        .catch(error => {
          console.error('Error fetching mangas:', error);
        });
    },
    fetchManga({ commit }, id) {
      apiClient.get(`/series/${id}`)
        .then(response => {
          commit('setMangas', response.data);
        })
        .catch(error => {
          console.error('Error fetching manga:', error);
        });
    },
    fetchVolumes({ commit }, id) {
      apiClient.get(`/volumes/series/${id}`)
        .then(response => {
          commit('setVolumes', response.data);
        })
        .catch(error => {
          console.error('Error fetching volumes:', error);
        });
    },
    fetchVolume({ commit }) {
      apiClient.get('/volumes/')
        .then(response => {
          commit('setVolumes', response.data);
        })
        .catch(error => {
          console.error('Error fetching volumes:', error);
        });
    },
    fetchChapters({ commit }) {
      apiClient.get('/chapters/')
        .then(response => {
          commit(
            'setChapters',
            response.data.sort((x, y) => new Date(y.updatedAt) - new Date(x.updatedAt))
          );
        })
        .catch(error => {
          console.error('Error fetching chapters:', error);
        });
    },
    async fetchSeriesChapters({ commit },id) {
    await  apiClient.get('/chapters/series/'+id)
        .then(response => {
          commit(
            'setChapters',
            response.data.sort((x, y) => new Date(y.updatedAt) - new Date(x.updatedAt))
          );
          
        })
        .catch(error => {
          console.error('Error fetching chapters:', error);
        });
    },
    createVolume({ commit }, volume) {
      return apiClient.post('/volumes/create', volume)
        .then(response => {
          commit('addVolume', response.data);
        })
        .catch(error => {
          console.error('Error creating volume:', error);
          throw error;
        });
    },
    createManga({ commit }, manga) {
      return apiClient.post('/series/', manga)
        .then(response => {
          commit('addManga', response.data);
        })
        .catch(error => {
          console.error('Error creating manga:', error);
          throw error;
        });
    },
    updateManga({ commit }, manga) {
      return apiClient.put(`/mangas/${manga.id}`, manga)
        .then(response => {
          commit('updateManga', response.data);
        })
        .catch(error => {
          console.error('Error updating manga:', error);
          throw error;
        });
    },
    deleteManga({ commit }, mangaId) {
      return apiClient.delete(`/mangas/${mangaId}`)
        .then(() => {
          commit('deleteManga', mangaId);
        })
        .catch(error => {
          console.error('Error deleting manga:', error);
          throw error;
        });
    },
    deleteUser({ commit }, userID) {
      return apiClient.delete(`/users/${userID}`)
        .then(() => {
          commit('deleteUser', userID);
        })
        .catch(error => {
          console.error('Error deleting user:', error);
          throw error;
        });
    },
    fetchSliders({ commit }) {
      apiClient.get('/slider')
        .then(response => {
          commit('setSliders', response.data);
        })
        .catch(error => {
          console.error('Error fetching sliders:', error);
        });
    },
    createSlider({ commit }, slider) {
      return apiClient.post('/slider/', slider)
        .then(response => {
          commit('addSlider', response.data);
        })
        .catch(error => {
          console.error('Error creating slider:', error);
          throw error;
        });
    },
    updateSliders({ commit }, slider) {
      return apiClient.put(`/slider/${slider.id}`, slider)
        .then(response => {
          commit('updateSlider', response.data);
        })
        .catch(error => {
          console.error('Error updating slider:', error);
          throw error;
        });
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    users: state => state.users,
    mangas: state => state.mangas,
    sliders: state => state.sliders,
    volumes: state => state.volumes,
    chapters: state => state.chapters,
  },
});
