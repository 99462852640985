<template>
    <div>
      <h3>Manage Mangas</h3>
      <button @click="addManga">Add Manga</button>
      <!-- Manga ekleme formu ve listeleme alanı -->
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      addManga() {
        this.$router.push('/admin/create-manga');
      }
    }
  };
  </script>
  
  <style scoped>
  button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  