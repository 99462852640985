<template>
  <div class="home">
    <div><Slider></Slider></div>
    <div class="cont">
      <!--<div> <MostViewed></MostViewed></div>-->
      <div><RecentlyUpdated /></div>
      <div><NewRealeseVue /></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Slider from "@/components/Slider/SliderComp.vue";
//import MostViewed from '@/components/MostViewed/MostViewed.vue'
import RecentlyUpdated from "@/components/RecentlyUpdate/RecentlyUpdated.vue";
import NewRealeseVue from "@/components/NewRealese/newRea3.vue";
//import NewRealeseVue from '../components/NewRealese/NewRealese.vue';

export default {
  name: "HomeView",
  components: {
    Slider,
    // MostViewed,
    RecentlyUpdated,
    NewRealeseVue,
  },
  created() {
    // console.log(process.env.VUE_APP_VERSION)
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: #0d1626;
}
.cont {
  margin-top: 250px;
}
</style>
