<template>
  <div class="all" v-if="chapters && volumes">
    <div class="tab-switcher">
      <button
        class="tab-button"
        :class="{ active: activeTab === 'chapter' }"
        @click="switchTab('chapter')"
      >
        CHAPTER
      </button>
      <button
        class="tab-button"
        :class="{ active: activeTab === 'volume' }"
        @click="switchTab('volume')"
      >
        VOLUME
      </button>
    </div>

    <div class="chapter-list-container">
      <div class="top-bar">
        <button
          class="tab-button"
          @click="toggleOrder"
        >
        <Icon icon="ri:exchange-2-line" width="2em" height="2em"  style="color: white" />
         
        </button>
        <input
          type="text"
          class="search-input"
          v-model="searchQuery"
          placeholder="Search..."
        />
      </div>

      <!-- Eğer activeTab 'chapter' ise chapter listesi, 'volume' ise volume listesi gösterilir -->
      <div v-if="activeTab === 'chapter'">
        <div class="chapter-list">
          <div
            class="chapter-item"
            v-for="chapter in filteredChapters"
            :key="chapter"
          >
            <span class="chapter-title">{{ chapter.title }}</span>
            <span class="chapter-date">{{ new Date(chapter.updatedAt).toLocaleString() }}</span>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'volume'" class="volume-list">
        <div
          class="volume-item"
          v-for="volume in filteredVolumes"
          :key="volume.volumeNumber"
        >
          <img :src="volume.coverImage" alt="Volume Cover" class="volume-cover" />
          <div class="volume-title">{{ volume.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  props: {
    mangaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      orderType: "desc", // Varsayılan sıralama tipi
      activeTab: "chapter", // Varsayılan tab (chapter)
      searchQuery: "", // Arama kutusundaki değer
    };
  },
  computed: {
    ...mapGetters(["volumes", "chapters"]),
    
    filteredChapters() {
    return this.chapters
      .slice() // Orijinal diziyi bozmamak için kopyasını al
      .filter((chapter) => {
        if (this.searchQuery=="") {
          // Arama kutusu boşsa, tüm bölümleri göster
          return true;
        }
        // Arama kutusu doluysa, filtreleme yap
        return chapter.title
          .toString()
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      })
      .sort((x, y) => {
        return this.orderType === 'asc'
          ? new Date(x.updatedAt) - new Date(y.updatedAt)  // Artan sıralama
          : new Date(y.updatedAt) - new Date(x.updatedAt); // Azalan sıralama
      });
     
  },
    
    // Arama sorgusuna göre volümleri filtrele ve sıralamayı orderType'a göre yap
    filteredVolumes() {
    return this.volumes
      .slice() // Orijinal diziyi bozmamak için kopyasını al
      .filter((volume) =>
        volume.title.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
      )
      .sort((x, y) => {
        return this.orderType === 'asc'
          ? new Date(x.createdAt) - new Date(y.createdAt)  // Artan sıralama
          : new Date(y.createdAt) - new Date(x.createdAt); // Azalan sıralama
      });
  },
},

  async created() {
    await this.fetchVolumes(this.mangaId);
    await this.fetchSeriesChapters(this.mangaId);
  },
  methods: {
    ...mapActions(["fetchVolumes", "fetchSeriesChapters"]),

    // Tab geçişi
    switchTab(tab) {
      this.activeTab = tab;
    },

    // Sıralama tipini değiştir (asc/desc)
    toggleOrder() {
      this.orderType = this.orderType === "asc" ? "desc" : "asc";
      console.log(this.filteredChapters)
    },
  },
};
</script>

<style scoped>
.all {
  background-color: #182335;
  border-radius: 20px;
}

.chapter-list-container {
  background-color: #182335;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-switcher {
  display: flex;
  justify-content: flex-end;
  background-color: #0d1626;
}

.tab-button {
  background-color: #34495e;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tab-button.active {
  background-color: #2980b9;
}

.search-input {
  background-color: #1e2c43;
  color: white;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
}

.chapter-list {
  max-height: 400px;
  overflow-y: auto;
}

.chapter-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #141d2c;
  border-radius: 5px;
  border: 1px solid #182335;
  color:rgba(255, 255, 255, .5)
}
.chapter-item:hover{
  background-color: #162131;
  color: #388AC6;
  cursor: pointer;
}

.chapter-title {
  font-size: 1rem;
}

.chapter-date {
  font-size: 0.9rem;
  opacity: 0.7;
}

/* Volume Listesi */
.volume-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.volume-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #34495e;
  border-radius: 5px;
  padding: 10px;
  color:rgba(255, 255, 255, .5)
}
.volume-item:hover{
  background-color: #162131;
  color: #388AC6;
  cursor: pointer;
}
.volume-cover {
  width: 150px;
  height: 225px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.volume-title {
  font-size: 1rem;
  
}
</style>
