<template>
    <div class="manga-card" >
        
      <img :src="manga.coverImageUrl" alt="manga cover" @click="seeSeries(manga.id)"  @mouseenter="showDetails = true" @mouseleave="showDetails = false" />
      <div class="extra-details" v-if="showDetails" @mouseenter="showDetails = true" @mouseleave="showDetails = false">
        <p class="status">{{manga.status}}</p>
        <h3>{{ manga.title }}</h3>
       
        <p class="rating">{{manga.rating}} by {{manga.reviews}} reviews</p>
        <div class="genres">
          <span class="genre" v-for="(genre,index) in manga.genres" :key="genre" v-show="index<3">{{genre}}</span>
        
        </div>
      </div>
      <div class="manga-info" v-if="!showDetails">
        <div class="manga-header">
          <div class="header-upper">
            <span class="type">{{manga.type}}</span>
          <div class="view-options">
           
            <button :class="{ active: viewMode === 'chapter' }" @click="setViewMode('chapter')">Chapter</button>
            <button :class="{ active: viewMode === 'volume' }" @click="setViewMode('volume')">Volume</button>
          </div>
        </div>
        <div class="manga-title">
           
            <div class="cursor-pointer hover:text-indigo-600" @click="seeSeries(manga.id)"> <h4>{{ manga.title }}</h4></div>
         
         
        </div>
        
    </div>
    <div class="manga-chapter">
        <div class="manga-details" v-if="viewMode === 'chapter'">
          <div class="chapter" v-for="(chapter,index) in chapters" :key="chapter.number" v-show="chapter.seriesId==manga.id && index<=4" >
            <span>{{ chapter.title }}</span>
            <span>{{ new Date(chapter.updatedAt).toLocaleString()}}</span>
          </div>
        </div>
        <div class="manga-details" v-else>
          <div class="volume" v-for="(volume,index) in volumes" :key="volume.number" v-show="index<3 && volume.seriesId==manga.id">
            <span> {{ volume.title }}</span>
            <span>{{ new Date(volume.updatedAt).toLocaleString() }}</span>
          </div>
        </div>
    </div>
      </div>
     
    </div>
  </template>
  <script>
  export default {
    name: 'MangaCard',
    props: {
      manga: Object,
      volumes:Object,
      chapters:Object,

    },
    created(){

    },
    data() {
      return {
        viewMode: 'chapter',
        showDetails: false
      };
    },
    methods: {
      setViewMode(mode) {
        this.viewMode = mode;
      },
     seeSeries(mangaId)
     {
      this.$router.push('/manga/'+mangaId);
     }
    }
  };
  </script>

<style scoped>
.manga-card {
  display: flex;
  flex-direction: row;
 height: 12rem;
  box-shadow: 0 3px 7px -1px aqua;
  border-radius: 10px;
  background: #182335;
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
}
.manga-card:hover {
  background: #1f2d44;
  border: 0px solid aqua;
}

.manga-card img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 30%;

  background-size: cover;
  background-position: center;
}
.manga-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

height: 50%;
}
.header-upper{
  display: flex;
  justify-content: space-between;
}
.manga-chapter{
    height: 50%;
}
.manga-info {
  padding: 10px;
  width: 100%;
 
}

.manga-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  gap: 10px;

  margin-bottom: 10px;
}

.manga-title h3 {
  margin: 0;
}

.type {
  color: #337CBE;
  padding: 3px 6px;
  border-radius: 5px;
  font-size: 1em;
}

.manga-details .chapter,
.manga-details .volume {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  display: flex;

  height: 1.5rem;

  border-radius: 20px;
  background: #141d2c;

  color: #658d9c;
}

.manga-details .chapter span,
.manga-details .volume span {
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
}

h2 {
  margin: 0;
}
.view-options {
  display: flex;
  
  height: 25px;
 
border-radius: 5px;
  margin-bottom: 10px;
  background: #23334e;
}

.view-options button {
  background: none;
  height: 25px;

  border: none;
  color: white;
  padding: 5px 5px;
  cursor: pointer;
}

.view-options button.active {
  background-color: #141d2c;
  border-radius: 5px;
}
@media (min-width: 1200px) {
  .manga-grid {
    grid-template-columns: repeat(3, 1fr); /* En büyük ekranlarda 3 kart */
  }
}

@media (max-width: 900px) {
  .manga-grid {
    grid-template-columns: repeat(2, 1fr); /* Orta ekranlarda 2 kart */
  }
}

@media (max-width: 600px) {
  .manga-grid {
    grid-template-columns: repeat(1, 1fr); /* Küçük ekranlarda 1 kart */
  }
}
.extra-details {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#20324C;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    gap: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  .status {
    text-transform: uppercase;
    letter-spacing: 8px;
    color: #51a6dd;
  }
 
  .manga-card:hover .extra-details {
    opacity: 1;
    pointer-events: auto;
  }
  .extra-details:hover>  img{
    opacity: 0.3;
  }
  .manga-card img:hover {
    opacity: 0.3;
  }
  
  .languages img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .rating {
    margin: 10px 0;
  }
  
  .genres {
    display: flex;
    gap: 10px;
  }
  
  .genre {
    background-color: #007bff;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 0.8em;
  }
</style>
