<template>
  <div>
    <h2 style="cursor: pointer" @click="isShow = !isShow">Add New Manga +</h2>
    <div v-show="isShow">
      <span> Selected: {{ selected }}</span>
      <form @submit.prevent="create" class="flex flex-col text-black">
        <select v-model="selected" >
          <option disabled value="">Please select one</option>
          <option v-for="man in mangas" :key="man" :value="man">
            {{ man.title }}
          </option>
        </select>
        <button type="submit">Add Manga</button>
      </form>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
    components:{

    },

  data() {
    return {
      isShow: false,
      selected:"",
      filteredManga:[],
      newSlider: {
        seriesId: "",
       
      },
    };
  },

  computed: {
    ...mapGetters(["manga", "mangas", "sliders"]),

  },
  created() {
    this.fetchMangas();
    this.fetchSliders();
this.filteredManga=this.mangas.filter((slider => !this.sliders.some(manga => manga.id === slider.mangaID)))

  },
  methods: {
    ...mapActions(["fetchManga", "fetchMangas","fetchSliders", "createliders"]),

    create() {
     
      this.newSlider = {
        seriesId: this.selected.id,
       
       
      };
      this.$emit("create-slider", this.newSlider);
    },
  },
};
</script>
