<template>
  <div class="manga-management">
    <h1>Manga Management</h1>
    <AddMangaForm @create-manga="create" v-if="!editMangaData" />
    <MangaList :mangas="mangas" @edit-manga="edit" @delete-manga="deleteM" v-if="!editMangaData" />
    <EditMangaForm :editMangaData="editMangaData" @update-manga="update" v-if="editMangaData" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddMangaForm from '../components/Admin/MangaManagement/NewManga.vue';
import EditMangaForm from '../components/Admin/MangaManagement/EditManga.vue';
import MangaList from '../components/Admin/MangaManagement/MangaList.vue';

export default {
  components: {
    AddMangaForm,
    EditMangaForm,
    MangaList
  },
  data() {
    return {
      editMangaData: null
    };
  },
  computed: {
    ...mapGetters(['mangas'])
  },
  created() {
    this.fetchMangas();
  },
  methods: {
    ...mapActions(['fetchMangas', 'createManga', 'updateManga', 'deleteManga']),
    create(newManga) {
      this.createManga(newManga).then(() => {
        this.fetchMangas();
      });
    },
    edit(manga) {
      this.editMangaData = { ...manga };
    },
    update(updatedManga) {
      this.updateManga(updatedManga).then(() => {
        this.editMangaData = null;
        this.fetchMangas();
      });
    },
    deleteM(mangaId) {
      this.deleteManga(mangaId).then(() => {
        this.fetchMangas();
      });
    },
    visible() {
      
      
    }
  }
};
</script>

<style scoped>
.manga-management {
  width: 100%;
  padding: 20px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
}

h2 {
  margin-top: 20px;
}

form {
  margin-bottom: 20px;
}

input {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
